// extracted by mini-css-extract-plugin
export var approachInfoRoundBlock__between = "c4_xP";
export var approachInfoRoundBlock__card = "c4_xN";
export var approachInfoRoundBlock__contentFive = "c4_xZ";
export var approachInfoRoundBlock__contentFour = "c4_xY";
export var approachInfoRoundBlock__contentOne = "c4_xV";
export var approachInfoRoundBlock__contentSix = "c4_x0";
export var approachInfoRoundBlock__contentThree = "c4_xX";
export var approachInfoRoundBlock__contentTwo = "c4_xW";
export var approachInfoRoundBlock__description = "c4_xT";
export var approachInfoRoundBlock__icon = "c4_xR";
export var approachInfoRoundBlock__imageDesktop = "c4_xQ";
export var approachInfoRoundBlock__title = "c4_xS";