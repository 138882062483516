// extracted by mini-css-extract-plugin
export var toolsHelpingUsSection__image = "dc_yz";
export var toolsHelpingUsSection__logo = "dc_yB";
export var toolsHelpingUsSection__techStack = "dc_yD";
export var toolsHelpingUsSection__techStackTitle = "dc_yC";
export var toolsHelpingUsSection__techStackWrapper = "dc_yN";
export var toolsHelpingUsSection__techStackWrapper_axure = "dc_yV";
export var toolsHelpingUsSection__techStackWrapper_figma = "dc_yW";
export var toolsHelpingUsSection__techStackWrapper_github = "dc_yS";
export var toolsHelpingUsSection__techStackWrapper_gitlab = "dc_yR";
export var toolsHelpingUsSection__techStackWrapper_jira = "dc_yQ";
export var toolsHelpingUsSection__techStackWrapper_slack = "dc_yT";
export var toolsHelpingUsSection__techStackWrapper_trello = "dc_yP";
export var toolsHelpingUsSection__techStack_axure = "dc_yG";
export var toolsHelpingUsSection__techStack_figma = "dc_yJ";
export var toolsHelpingUsSection__techStack_github = "dc_yH";
export var toolsHelpingUsSection__techStack_gitlab = "dc_yM";
export var toolsHelpingUsSection__techStack_jira = "dc_yK";
export var toolsHelpingUsSection__techStack_slack = "dc_yF";
export var toolsHelpingUsSection__techStack_trello = "dc_yL";
export var toolsHelpingUsSection__wrapper = "dc_yy";